import React from 'react';

const Telegram = () => {
  return (
    <svg
      width='22'
      height='20'
      viewBox='0 0 22 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9732 4.09982C11.3013 4.84567 7.96004 6.38938 2.94925 8.73096C2.13558 9.07802 1.70934 9.41754 1.67054 9.74953C1.60496 10.3106 2.26003 10.5315 3.15205 10.8324C3.27339 10.8733 3.39911 10.9157 3.52799 10.9606C4.40561 11.2666 5.58615 11.6246 6.19987 11.6388C6.75657 11.6517 7.37791 11.4055 8.06389 10.9003C12.7456 7.51061 15.1624 5.7973 15.3141 5.76036C15.4211 5.7343 15.5695 5.70154 15.67 5.79735C15.7705 5.89316 15.7606 6.07462 15.7499 6.1233C15.685 6.42003 13.1137 8.98413 11.783 10.311C11.3682 10.7247 11.0739 11.0181 11.0138 11.0851C10.879 11.2352 10.7417 11.3772 10.6097 11.5137C9.79438 12.3567 9.18296 12.9889 10.6436 14.0213C11.3454 14.5174 11.9071 14.9277 12.4674 15.337C13.0794 15.7839 13.6897 16.2298 14.4794 16.785C14.6806 16.9265 14.8728 17.0734 15.0599 17.2165C15.7721 17.761 16.4119 18.2503 17.2023 18.1723C17.6616 18.1269 18.1361 17.6637 18.377 16.2821C18.9464 13.0171 20.0657 5.9427 20.3244 3.02752C20.3471 2.77212 20.3185 2.44524 20.2956 2.30176C20.2728 2.15827 20.2249 1.95382 20.051 1.80248C19.8451 1.62325 19.5272 1.58545 19.385 1.58801C18.7385 1.60035 17.7466 1.97028 12.9732 4.09982Z'
        stroke='#1D1D1D'
        strokeWidth='2'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Telegram;
