import React from 'react';

const Twitter = () => {
  return (
    <svg
      width='24'
      height='20'
      viewBox='0 0 24 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.6654 3.64275C21.8808 3.99038 21.0381 4.22527 20.1521 4.33144C21.0559 3.79026 21.7493 2.93152 22.0763 1.90929C21.2307 2.41101 20.294 2.77555 19.2971 2.97098C18.4985 2.12163 17.3616 1.58984 16.1026 1.58984C13.6861 1.58984 11.7262 3.54974 11.7262 5.96813C11.7262 6.31012 11.7657 6.64272 11.839 6.96405C8.20011 6.78084 4.97653 5.03798 2.81745 2.39034C2.44164 3.03862 2.22554 3.79026 2.22554 4.59075C2.22554 6.10906 2.99879 7.44979 4.17228 8.23431C3.45447 8.21083 2.77987 8.01446 2.18984 7.68656V7.74293C2.18984 9.86349 3.69781 11.6327 5.70092 12.0348C5.33262 12.1344 4.94647 12.187 4.5481 12.187C4.26623 12.187 3.99095 12.1607 3.72412 12.1099C4.28127 13.8481 5.89729 15.1146 7.81302 15.1484C6.31444 16.3229 4.4269 17.0228 2.37681 17.0228C2.02354 17.0228 1.67497 17.0022 1.33203 16.9618C3.26938 18.2048 5.56938 18.9282 8.04039 18.9282C16.0923 18.9282 20.4941 12.2593 20.4941 6.47548C20.4941 6.28757 20.4894 6.09779 20.4809 5.90988C21.3359 5.29166 22.0781 4.52217 22.6635 3.64557L22.6654 3.64275Z'
        stroke='#1D1D1D'
        strokeWidth='2'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Twitter;
