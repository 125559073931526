import React from 'react';

const Medium = () => {
  return (
    <svg
      width='28'
      height='18'
      viewBox='0 0 28 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 9.07644C2 5.64842 4.75743 2.88281 8.14462 2.88281C11.5318 2.88281 14.2895 5.64934 14.2895 9.07644C14.2895 12.5036 11.5319 15.2703 8.14462 15.2703C4.75726 15.2703 2 12.5045 2 9.07644ZM8.14462 0.882812C3.64019 0.882812 0 4.55656 0 9.07644C0 13.5963 3.63988 17.2703 8.14462 17.2703C11.3715 17.2703 14.1547 15.3845 15.4741 12.6537C15.6535 13.263 15.8787 13.8272 16.1445 14.331C16.8569 15.6815 18.0137 16.8482 19.5551 16.8482C21.0963 16.8482 22.2531 15.6819 22.9656 14.3315C23.0649 14.1431 23.1586 13.9464 23.2464 13.742C23.3615 14.2582 23.5062 14.7315 23.6896 15.1064C23.7905 15.3125 23.9282 15.5413 24.1207 15.7315C24.3163 15.9249 24.6392 16.1436 25.0769 16.1436C25.5144 16.1436 25.8373 15.925 26.0329 15.7317C26.2255 15.5414 26.3631 15.3127 26.464 15.1066C26.6679 14.6903 26.8238 14.1525 26.9445 13.5694C27.1894 12.3868 27.3333 10.7989 27.3333 9.07644C27.3333 7.35418 27.1896 5.76623 26.9447 4.58362C26.824 4.00054 26.6682 3.46269 26.4644 3.04636C26.3634 2.84024 26.2258 2.61148 26.0332 2.42119C25.8375 2.22781 25.5146 2.00925 25.0771 2.00925C24.6395 2.00925 24.3166 2.22789 24.1209 2.42124C23.9284 2.61153 23.7907 2.84029 23.6898 3.04642C23.5062 3.42137 23.3615 3.89487 23.2463 4.41123C23.1586 4.20686 23.0649 4.01013 22.9656 3.82183C22.2532 2.47135 21.0964 1.30471 19.5551 1.30471C18.0137 1.30471 16.8569 2.47135 16.1445 3.82183C15.8787 4.3257 15.6534 4.88992 15.4741 5.49926C14.1546 2.76845 11.3713 0.882812 8.14462 0.882812ZM16.9826 9.07644C16.9826 7.33621 17.357 5.8099 17.9135 4.75499C18.4941 3.65439 19.1235 3.30471 19.5551 3.30471C19.9866 3.30471 20.616 3.65439 21.1966 4.75499C21.7531 5.8099 22.1275 7.33621 22.1275 9.07644C22.1275 10.8172 21.7531 12.3435 21.1966 13.3982C20.6161 14.4986 19.9868 14.8482 19.5551 14.8482C19.1235 14.8482 18.4941 14.4985 17.9135 13.3979C17.357 12.343 16.9826 10.8167 16.9826 9.07644Z'
        fill='#1D1D1D'
      />
    </svg>
  );
};

export default Medium;
