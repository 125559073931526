import React from 'react';

const AMBLogoText1 = () => {
  return (
    <svg
      width='296'
      height='28'
      viewBox='0 0 296 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M120.066 23.0033C117.344 25.9554 114.161 27.7573 109.177 27.7573C101.356 27.7573 95.5288 21.6615 95.5288 13.9553V13.8787C95.5288 6.24923 101.241 0 109.331 0C114.315 0 117.305 1.72525 119.874 4.17894L116.845 7.66777C114.66 5.67415 112.398 4.37063 109.292 4.37063C104.232 4.37063 100.474 8.54957 100.474 13.802V13.8787C100.474 19.1311 104.193 23.3867 109.331 23.3867C112.59 23.3867 114.737 22.0832 117.037 19.9362L120.066 23.0033ZM0 27.2973H4.8307L7.16523 21.8176H7.14252L9.40367 16.3908H9.42274L13.917 5.90418L18.556 16.6391H18.5517L20.7095 21.8176H20.7012L23.0033 27.2973H27.9874L16.179 0.268371H11.8084L0 27.2973ZM50.8005 27.2973H55.5162V17.9426H61.4204L68.0146 27.2973H73.6121L66.3661 17.1375C70.0849 16.064 72.7303 13.4186 72.7303 9.00963V8.93295C72.7303 6.59428 71.9252 4.639 70.5067 3.14378C68.7814 1.45688 66.136 0.460065 62.7622 0.460065H50.8005V27.2973ZM55.5162 13.7637V4.75402H62.3788C65.906 4.75402 67.938 6.28757 67.938 9.20133V9.278C67.938 12.0384 65.791 13.7637 62.4172 13.7637H55.5162ZM140.933 27.2973H145.763L148.093 21.8301H148.081L150.342 16.4033H150.35L154.85 5.90418L159.489 16.6391H159.485L161.648 21.8301H161.639L163.936 27.2973H168.92L157.112 0.268371H152.741L140.933 27.2973ZM201.74 27.2973H191.733V0.460065H201.74C210.174 0.460065 216.002 6.24923 216.002 13.802V13.8787C216.002 21.4314 210.174 27.2973 201.74 27.2973ZM211.056 13.8787C211.056 8.62624 207.376 4.75402 201.74 4.75402H196.449V23.0033H201.74C207.376 23.0033 211.056 19.2078 211.056 13.9553V13.8787ZM240.202 27.2973H244.918V0.460065H240.202V27.2973ZM272.843 27.2973H268.013L279.821 0.268371H284.192L296 27.2973H291.016L288.294 20.818H288.292L286.551 16.6391H286.569L281.93 5.90418L277.329 16.6391H277.343L275.526 20.9995L272.843 27.2973Z'
        fill='white'
      />
    </svg>
  );
};

export default AMBLogoText1;
