import React from 'react';

const AmbLogoText2 = () => {
  return (
    <svg
      width="296"
      height="30"
      viewBox="0 0 296 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2101 29.3953C18.0069 29.3953 22.0686 26.3589 22.0686 21.1536V21.0747C22.0686 16.4609 19.0322 14.4892 13.2354 12.9907C7.91177 11.7288 6.64987 10.7824 6.64987 8.65293V8.57406C6.64987 6.72065 8.30611 5.30102 11.1454 5.30102C13.6692 5.30102 16.1141 6.28688 18.6379 8.14028L21.2405 4.4729C18.4013 2.22516 15.286 1.0027 11.2242 1.0027C5.74289 1.0027 1.79947 4.27573 1.79947 9.00783V9.08671C1.79947 14.1343 5.11194 15.9483 10.9876 17.3284C16.1141 18.472 17.2182 19.6156 17.2182 21.5479V21.6268C17.2182 23.6774 15.3254 25.097 12.3284 25.097C8.89762 25.097 6.29497 23.8351 3.61344 21.5085L0.695312 24.9787C4.00778 27.9757 7.9512 29.3953 12.2101 29.3953Z"
        fill="white"
      />
      <path
        d="M53.6939 29.001H58.5443V5.89254H67.3382V1.39704H44.9001V5.89254H53.6939V29.001Z"
        fill="white"
      />
      <path
        d="M86.2139 29.001H91.1826L94.0219 22.3366H107.075L109.874 29.001H115.001L102.855 1.19987H98.3596L86.2139 29.001ZM95.7964 18.0383L100.528 6.99669L105.3 18.0383H95.7964Z"
        fill="white"
      />
      <path
        d="M139.452 29.001H144.302V20.9958L148.6 16.5792L158.025 29.001H163.94L151.913 13.2667L163.428 1.39704H157.394L144.302 15.2779V1.39704H139.452V29.001Z"
        fill="white"
      />
      <path d="M188.058 29.001H192.908V1.39704H188.058V29.001Z" fill="white" />
      <path
        d="M220.014 29.001H224.786V9.36274L240.007 29.001H244.069V1.39704H239.298V20.4832L224.51 1.39704H220.014V29.001Z"
        fill="white"
      />
      <path
        d="M283.978 29.4742C288.868 29.4742 292.733 27.5025 295.493 25.1758V13.5822H283.742V17.8411H290.8V22.9675C289.105 24.2294 286.778 25.0575 284.136 25.0575C278.497 25.0575 274.751 20.8775 274.751 15.199V15.1201C274.751 9.83595 278.655 5.41932 283.702 5.41932C287.172 5.41932 289.302 6.52348 291.431 8.33745L294.507 4.67007C291.668 2.26459 288.671 0.923828 283.899 0.923828C275.539 0.923828 269.664 7.4699 269.664 15.199V15.2779C269.664 23.3224 275.342 29.4742 283.978 29.4742Z"
        fill="white"
      />
    </svg>
  );
};

export default AmbLogoText2;
