import React from 'react';

const AirdaoLogo = () => {
  return (
    <svg
      width='261'
      height='57'
      viewBox='0 0 261 57'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M97.6229 43.7306C97.6554 43.7958 97.6723 43.8677 97.6723 43.9405C97.6723 44.2008 97.4613 44.4118 97.201 44.4118H95.1234C94.3572 44.4118 93.6579 43.9752 93.3216 43.2867L90.1324 36.7579C89.7962 36.0695 89.0969 35.6328 88.3307 35.6328H71.8298C71.0636 35.6328 70.3644 36.0695 70.0281 36.7579L66.8389 43.2867C66.5026 43.9752 65.8034 44.4118 65.0372 44.4118H62.9595C62.6993 44.4118 62.4883 44.2008 62.4883 43.9405C62.4883 43.8677 62.5052 43.7958 62.5376 43.7306L77.4803 13.7058C77.8193 13.0247 78.5146 12.5941 79.2754 12.5941H80.8851C81.646 12.5941 82.3413 13.0247 82.6803 13.7058L97.6229 43.7306ZM84.6982 32.2025C86.1848 32.2025 87.1545 30.6415 86.4958 29.3088L81.8779 19.9657C81.1419 18.4768 79.0186 18.4768 78.2827 19.9657L73.6647 29.3088C73.0061 30.6415 73.9758 32.2025 75.4623 32.2025H84.6982Z'
        fill='#3568DD'
      />
      <path
        d='M105.13 12.5941C106.166 12.5941 107.006 13.4341 107.006 14.4702V42.5357C107.006 43.5718 106.166 44.4118 105.13 44.4118C104.093 44.4118 103.254 43.5718 103.254 42.5356V14.4702C103.254 13.4341 104.093 12.5941 105.13 12.5941Z'
        fill='#3568DD'
      />
      <path
        d='M146.957 22.3916C146.957 25.0754 145.95 27.2257 143.936 28.8425C141.922 30.4269 140.075 31.2191 133.204 31.2191C133.11 31.2191 133.061 31.3303 133.124 31.3998L144.255 43.7097C144.325 43.787 144.363 43.8874 144.363 43.9916C144.363 44.2237 144.175 44.4118 143.943 44.4118H140.962C140.397 44.4118 139.858 44.1733 139.478 43.7551L128.684 31.8758C128.304 31.4575 127.765 31.2191 127.2 31.2191H121.965C120.858 31.2191 119.96 32.1168 119.96 33.2243V42.56C119.96 43.5827 119.131 44.4118 118.108 44.4118C117.085 44.4118 116.256 43.5827 116.256 42.56V14.5992C116.256 13.4918 117.154 12.5941 118.262 12.5941L135.7 12.5941C139.209 12.5941 141.954 13.4186 143.936 15.0677C145.95 16.6845 146.957 18.9156 146.957 21.7611V22.3916ZM135.603 27.9209C137.974 27.9209 139.826 27.4197 141.158 26.4173C142.523 25.4149 143.205 24.0245 143.205 22.2461V21.6156C143.205 19.8371 142.539 18.4467 141.207 17.4443C139.875 16.442 138.007 15.9408 135.603 15.9408L121.965 15.9408C120.858 15.9408 119.96 16.8385 119.96 17.9459V25.9157C119.96 27.0232 120.858 27.9209 121.965 27.9209L135.603 27.9209Z'
        fill='#3568DD'
      />
      <path
        d='M169.065 12.5941C174.718 12.5941 179.038 13.9198 182.027 16.5713C185.016 19.2228 186.511 23.0706 186.511 28.1149V28.9395C186.511 33.9837 185.016 37.8316 182.027 40.4831C179.071 43.1022 174.75 44.4118 169.065 44.4118L156.548 44.4118C155.441 44.4118 154.543 43.5141 154.543 42.4066V14.5992C154.543 13.4918 155.441 12.5941 156.548 12.5941L169.065 12.5941ZM169.065 41.0651C173.58 41.0651 176.975 40.0627 179.25 38.058C181.556 36.0208 182.709 32.9975 182.709 28.988V28.0664C182.709 24.0892 181.556 21.082 179.25 19.0449C176.943 16.9755 173.532 15.9408 169.016 15.9408L160.252 15.9408C159.144 15.9408 158.246 16.8385 158.246 17.9459V39.0599C158.246 40.1674 159.144 41.0651 160.252 41.0651H169.065Z'
        fill='#3568DD'
      />
      <path
        d='M223.405 43.7306C223.437 43.7958 223.454 43.8677 223.454 43.9405C223.454 44.2008 223.243 44.4118 222.983 44.4118H220.905C220.139 44.4118 219.44 43.9752 219.104 43.2867L215.914 36.7579C215.578 36.0695 214.879 35.6328 214.113 35.6328H197.612C196.846 35.6328 196.146 36.0694 195.81 36.7579L192.621 43.2867C192.284 43.9752 191.585 44.4118 190.819 44.4118H188.741C188.481 44.4118 188.27 44.2008 188.27 43.9405C188.27 43.8677 188.287 43.7958 188.32 43.7306L203.262 13.7058C203.601 13.0247 204.296 12.5941 205.057 12.5941H206.667C207.428 12.5941 208.123 13.0247 208.462 13.7058L223.405 43.7306ZM210.48 32.2025C211.967 32.2025 212.936 30.6415 212.278 29.3088L207.66 19.9657C206.924 18.4768 204.801 18.4768 204.065 19.9657L199.447 29.3088C198.788 30.6415 199.758 32.2025 201.244 32.2025H210.48Z'
        fill='#3568DD'
      />
      <path
        d='M243.116 44.9453C239.477 44.9453 236.31 44.2986 233.613 43.0052C230.917 41.6795 228.837 39.804 227.375 37.3789C225.914 34.9538 225.183 32.1245 225.183 28.891V28.1634C225.183 24.8976 225.914 22.0521 227.375 19.627C228.837 17.2018 230.917 15.3426 233.613 14.0492C236.31 12.7234 239.477 12.0605 243.116 12.0605C246.722 12.0605 249.873 12.7234 252.57 14.0492C255.266 15.3749 257.345 17.2503 258.807 19.6755C260.269 22.1006 261 24.9299 261 28.1634V28.891C261 32.1245 260.269 34.9538 258.807 37.3789C257.345 39.804 255.266 41.6795 252.57 43.0052C249.873 44.2986 246.722 44.9453 243.116 44.9453ZM243.116 41.5016C247.534 41.5016 250.978 40.3861 253.447 38.155C255.948 35.9238 257.199 32.852 257.199 28.9395V28.1149C257.199 24.2024 255.948 21.1305 253.447 18.8994C250.945 16.636 247.502 15.5042 243.116 15.5042C238.697 15.5042 235.221 16.636 232.687 18.8994C230.186 21.1305 228.935 24.2024 228.935 28.1149V28.9395C228.935 32.852 230.186 35.9238 232.687 38.155C235.221 40.3861 238.697 41.5016 243.116 41.5016Z'
        fill='#3568DD'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.13957 56.4545C1.77425 56.6772 1.29844 56.5739 1.05829 56.2198L0.138426 54.8636C-0.101728 54.5095 -0.021086 54.0299 0.315869 53.7663C3.34555 51.3957 5.83591 48.7236 7.79443 45.8618C15.087 35.2056 15.087 21.7959 7.79442 11.1397C5.83591 8.27784 3.34555 5.60583 0.315871 3.2352C-0.0210842 2.97155 -0.101726 2.49199 0.138428 2.1379L1.05829 0.781632C1.29844 0.427543 1.77425 0.324291 2.13957 0.546989L42.9996 25.4551C45.28 26.8452 45.28 30.1563 42.9996 31.5464L2.13957 56.4545Z'
        fill='url(#paint0_linear_2498_11568)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2498_11568'
          x1='49.9283'
          y1='28.998'
          x2='-16.4428'
          y2='28.998'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3568DD' />
          <stop offset='1' stopColor='#3568DD' stopOpacity='0.66' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default AirdaoLogo;
